<template>
    <div id="email_ok" class="email_modal modal1">
        <div class="email_modal_box modal_box">
            <div class="modal_close_btn" @click="$emit('closeResendEmail2')">
                <img src="/media/img/close_btn.png" alt="" />
            </div>
            <h3>인증메일 전송</h3>
            <p>
                인증 이메일을 전송했습니다.<br />
                이메일에서 인증하기 버튼을 클릭해주세요.
            </p>
            <!-- <button @click="sendEmail()">인증 메일 재발송</button> -->
        </div>
    </div>
</template>
<script>
import { defineComponent } from "@vue/composition-api";

export default defineComponent({
  name: 'CertificationEmail2',
  props: {
    email: String
  },
  methods: {
    // sendEmail () {
    // var toMail = this.email;
    // axios
    // .post("/api/user/getAuthEmail", { toMail })
    // .then(res => {
    // console.log(res.data.result);
    // })
    // .catch(err => {
    // console.log(err);
    // });
    // }
  }
});
</script>
