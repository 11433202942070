<template>
    <div>
        <ResendEmail2 :email="email" v-if="ShowResendEmail2" @closeResendEmail2="ShowResendEmail2 = false" />
        <header class="header mo_header" style="display: none">
            <div class="header_box">
                <div class="logo">
                    <a href="#">
                        <h2>회원가입</h2>
                    </a>
                </div>
                <div class="right_menu">
                    <div class="mo_header_close_btn">
                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M8 6.2225L14.2225 0L16 1.7775L9.7775 8L16 14.2225L14.2225 16L8 9.7775L1.7775 16L0 14.2225L6.2225 8L0 1.7775L1.7775 0L8 6.2225Z"
                                fill="white"
                            />
                        </svg>
                    </div>
                </div>
            </div>
        </header>
        <section class="login_main">
            <div class="login_main_box join_box">
                <img src="/media/img/join_1_icon.png" alt="" />
                <h2>가입완료</h2>
                <h3 class="join_sub_title">회원가입을 축하드립니다!</h3>
                <h3 class="join_sub_title">메일 인증 후 3일간 자유롭게 이용해보세요.</h3>
                <div class="email_check">
                    <h3 style="margin-bottom: 0px">{{ email }}</h3>
                </div>
                <p id="email_retry" @click="[sendEmail(), (ShowResendEmail2 = true)]">인증메일 재발송</p>
                <button @click="sendPlayer">바로가기</button>
            </div>
        </section>
    </div>
</template>

<script>
import { defineComponent } from '@vue/composition-api';
import ResendEmail2 from '@/components/modal/SignUp/ResendEmail2.vue';
import axios from 'axios';
import { getLoginUser } from '@/service/api/userApi';

export default defineComponent({
  data () {
    return {
      ShowResendEmail2: false,
      email: this.$route.params.email
    };
  },
  components: {
    ResendEmail2
  },
  methods: {
    sendEmail () {
      axios.post('/api/user/getAuthEmail', { toMail: this.email });
    },
    sendPlayer () {
      const aToken = this.$cookies.get('aToken');
      const email = this.$cookies.get('email');
      if (aToken) {
        getLoginUser(email).then((res) => {
          const { resultCd, result } = res.data;
          if (resultCd === '0000') {
            const userId = result.userId;
            const email = result.email;
            const comeFrom = result.comeFrom;
            const userType = result.userType;
            const signupCouponAt = result.signupCouponAt;
            const division = result.division;
            // 쿠키 셋팅
            this.$cookies.set('userId', userId);
            this.$cookies.set('email', email);
            this.$cookies.set('comeFrom', comeFrom);
            this.$cookies.set('userType', userType);
            // store 셋팅
            this.$store.commit('setEmail', email);
            this.$store.commit('setSignupCouponAt', signupCouponAt);
            this.$store.commit('setDivision', division);

            this.$router.push({ name: 'PlayerView' }).catch(() => {});
          } else {
            alert('인증에 실패했습니다.');
            this.$router.push({ name: 'Home' }).catch(() => {});
          }
        });
      } else {
        alert('이메일 인증을 완료해 주세요.');
        this.$router.push({ name: 'Home' }).catch(() => {});
      }
    }
  },
  mounted () {
    this.sendEmail();
  }
});
</script>
<style scoped>
#email_retry:hover {
  opacity: 100;
}

.join_sub_title {
  color: var(--whitefff);
  font-size: 24px;
  font-weight: 500;
  text-align: center;
  line-height: 35px !important;
}

.email_check {
  margin-top: 28px;
}
</style>
<style src="@/assets/css/content2.css"></style>
<style src="@/assets/css/font.css"></style>
